import { NavLink, Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModalPopup from 'shared/ModalPopup';
import AskModal from 'features/Modals/AskModal';
import MapModal from 'features/Modals/MapModal';
import logoImg from 'assets/images/logo_new.png';

const Footer = () => {
  const location = useLocation();
  const [askModalShow, setAskModalShow] = useState(false);
  const [mapModalShow, setMapModalShow] = useState(false);

  useEffect(() => {
    setAskModalShow(false);
  }, [location]);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    } );
  };

  return (
    <>
      <footer itemScope itemType="http://schema.org/WPFooter">
        <div className="container">
          <div className="footer__row">
            <div className="footer__col footer__col--1">
              <Link to="/">
                <img src={logoImg} alt="Cтроительные материалы в Орле" title="Универсал логотип" width="150" />
              </Link>
              <div className="copyrigt-block">
                {/* <div className="creative-box">
                  <span className="creative-descr">Создание и продвижение сайта</span>
                  <a href="https://sait-region.ru/" className="creative-link" target="_blank" rel="noreferrer">ООО "Регион центр"</a>
                </div> */}
                <div className="copyright">
                  <span className="glyphicon glyphicon-copyright-mark glyphicon glyphicon-"></span>
                  2021-{new Date().getFullYear()} Универсал
                </div>
              </div>
            </div>
            <div className="footer__col footer__col--2">
              <ul className="footer-nav">
                <li className="first"><NavLink to="/" itemProp="url">Главная</NavLink></li>
                <li><NavLink to="/catalog" itemProp="url">Каталог</NavLink></li>
                <li><NavLink to="/akczii" itemProp="url">Акции</NavLink></li>
                <li><NavLink to="/dostavka" itemProp="url">Доставка</NavLink></li>
                <li className="last"><NavLink to="/kontaktyi" itemProp="url">Контакты</NavLink></li>
              </ul>
            </div>
            <div className="footer__col footer__col--3">
              <div className="header-contacts-item">
                <a href="tel:84862510038" rel="nofollow noopener noreferrer">
                  <span className="glyphicon glyphicon-earphone"></span> 8 (4862) 51-00-38
                </a>
                <div className="header-contacts-small left-text"> Пн-Вс 9:00-19:00 </div>
              </div>
              <div className="header-contacts-item">
                <a href="mailto:universal57@bk.ru" target="_blank" rel="noreferrer">universal57@bk.ru</a>
                <div className="header-contacts-small">
                  <button className="callback-link" onClick={() => setAskModalShow(true)}>Написать нам</button>
                </div>
              </div>
            </div>
            <div className="footer__col footer__col--4">
              <div className="header-contacts-item">
                <a href="/kontaktyi">
                  <span className="glyphicon glyphicon-map-marker"></span> г. Орел, Наугорское шоссе, д. 19, стр. 1
                </a>
                <div className="header-contacts-small">
                  <button className="callback-link" onClick={() => setMapModalShow(true)}>Посмотреть все адреса</button>
                </div>
              </div>
              <div className="header-contacts-text-desktop">
                <p className="header-contacts-secr">Все предложения и цены, размещенные на сайте, носят информационный характер и не являются публичной офертой. </p>
                <p className="header-contacts-secr">Обязательно уточняйте наличие и действующие цены перед оформлением покупки, позвонив по телефону <a href="tel:84862510038">(4862) 51-00-38</a></p>
              </div>
            </div>
          </div>
          <div className="footer__descr-mobil">
            <p className="header-contacts-secr">Все предложения и цены, размещенные на сайте, носят информационный характер и не являются публичной офертой.</p>
            <p className="header-contacts-secr">Обязательно уточняйте наличие и действующие цены перед оформлением покупки, позвонив по телефону <a href="tel:84862510038">(4862) 51-00-38</a></p>
          </div>
          <div className="policy"> Отправляя любую форму на сайте, вы даете согласие на обработку персональных данных - <Link to="/politika-konfidenczialnosti">Положение о защите персональных данных</Link>. </div>
        </div>
        <meta itemProp="copyrightYear" content="2023" />
        <meta itemProp="copyrightHolder" content="ООО Универсал" />
        <div className="up-link-wrap">
          <span className="up-link" onClick={scrollTop}>
            <span className="glyphicon glyphicon-chevron-up"></span>
          </span>
        </div>
        {askModalShow && <ModalPopup setShow={setAskModalShow}><AskModal setShow={setAskModalShow} /></ModalPopup>}
        {mapModalShow && <ModalPopup setShow={setMapModalShow}><MapModal /></ModalPopup>}
      </footer>
      <div className="site-creator">
          <div className="container">
              <span className="site-creator_block">Сайт разработан при сотрудничестве с ООО&nbsp;«Регион центр».</span>
              По вопросам продвижения и технической поддержки сайта обращайтесь: <span className="site-creator_link">(4862) 509-139, </span>
              <span className="site-creator_link">manager@vorle.ru, </span> <span className="site-creator_link">www.sait-region.ru</span>
          </div>
      </div>
    </>
  )
}
export default Footer;